import React from 'react';
import { HashRouter, Route, Switch } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap/dist/js/bootstrap.min.js'; 
import loadable from '@loadable/component';
import ProtectedRoute from './component/ProtectedRoute';
// import { propTypes } from 'react-bootstrap/esm/Image';


const Login = loadable(() => import('./pages/Login'));
const Dashboard = loadable(() => import('./pages/Dashboard'));
const ManageLoanRequest = loadable(() => import('./pages/ManageLoanRequest'));
const ManageLoanReturn = loadable(() => import('./pages/ManageLoanReturn'));
const ManageLoanIssurance = loadable(() => import('./pages/ManageLoanIssurance'));
const ManageLoanAll = loadable(() => import('./pages/ManageLoanAll'));
const ReportGeneration = loadable(() => import('./pages/ReportGeneration'));
const AddAccounts = loadable(() => import('./pages/AddAccounts'));
const InventorySystem = loadable(() => import('./pages/InventorySystem'));
const AddNewEquipment = loadable(() => import('./pages/AddNewEquipment'));
const LoanEquipment = loadable(() => import('./pages/LoanEquipment'));
const NewLoan = loadable(() => import('./pages/NewLoan'));
const ManageInventory = loadable(() => import('./pages/ManageInventory'));
const ReportGenerationUser = loadable(() => import('./pages/ReportGenerationUser'));
const ReportGenerationLoan = loadable(() => import('./pages/ReportGenerationLoan'));
const ReportGenerationDate = loadable(() => import('./pages/ReportGenerationDate'));
const ReportGenerationValue = loadable(() => import('./pages/ReportGenerationValue'));
const Settings = loadable(() => import('./pages/Settings'));
const NonOperationalDays = loadable(() => import('./pages/NonOperationalDays'));

const NotFound = loadable(() => import('./pages/Notfound')); 
const KioskDeposit = loadable(() => import('./pages/KioskDeposit'));
function App() {
  return(
    <HashRouter>
      <Switch>
          <Route exact path="/" component={Login} />
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          <Route exact path="/manage-loan-request" component={ManageLoanRequest} />
          <Route exact path="/manage-loan-return" component={ManageLoanReturn} />
          <Route exact path="/manage-loan-issurance" component={ManageLoanIssurance} />
          <Route exact path="/manage-loan-all/:LoanStatusName" component={ManageLoanAll} />
          <ProtectedRoute exact path="/report-generation" component={ReportGeneration} />
          <ProtectedRoute exact path="/add-accounts" component={AddAccounts} />
          <ProtectedRoute exact path="/inventory-system/:EqmTypeID" component={InventorySystem} />
          <ProtectedRoute exact path="/add-new-equipment" component={AddNewEquipment} /> 
          <Route exact path="/loan-equipment" component={LoanEquipment} />
          <Route exact path="/new-loan" component={NewLoan} />
          <ProtectedRoute exact path="/manage-inventory" component={ManageInventory} />
          <ProtectedRoute exact path="/manage-inventory/:EqmTypeID/:EqmModelID" component={ManageInventory} />
          <Route exact path="/kiosk-deposit" component={KioskDeposit} />
          <Route exact path="/report-generation-user" component={ReportGenerationUser} />
          <Route exact path="/report-generation-loan" component={ReportGenerationLoan} />
          <Route exact path="/report-generation-date" component={ReportGenerationDate} />
          <Route exact path="/report-generation-value" component={ReportGenerationValue} />
          <Route exact path="/settings" component={Settings} /> 
          <Route exact path="/non-operational-days" component={NonOperationalDays} /> 
          <Route path="" component={NotFound} />
      </Switch> 
    </HashRouter>
    
  );
} 

export default App;