import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import rootReducer from './reducers'
var CryptoJS = require("crypto-js");
const initalState = {

}

function saveToLocalStorage(state) {
    //const serializedState = JSON.stringify(state);
    const serializedState = CryptoJS.AES.encrypt(JSON.stringify(state), 'my-nyp-key@1098$').toString();
    localStorage.setItem('nypKey', serializedState);
}

function loadFromLocalStorage() {
const storageData = localStorage.getItem('nypKey');
if (storageData !== null) {
    if(typeof storageData==='string')
    {
        var bytes = CryptoJS.AES.decrypt(storageData, 'my-nyp-key@1098$');
        var serializedState = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    else 
    {
        return '';
    }

  if (serializedState === null) return undefined;
     return serializedState;
}
return '';
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const presistedState = loadFromLocalStorage();

const middleware = [thunk]

const store = createStore(rootReducer, presistedState, composeWithDevTools(applyMiddleware(...middleware)))

store.subscribe(() => saveToLocalStorage(store.getState()));


export  default store;