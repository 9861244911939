import {USER_LOGIN, USER_LOGOUT} from '../types'

const initialState = {
    data:[],
    loggedin:"no"
}

export default function(state = initialState, action){

    switch(action.type){

        case USER_LOGIN:
        return {
            ...state,
            data:action.payload,
            loggedin:"yes"

        }
        case USER_LOGOUT:
            return {
                ...state,
                data:[],
                loggedin:"no"
    
            }        
        default: return state
    }

}